<template>
    <v-btn width="100%" max-width="600" class="rounded-0 mx-auto" color="primary" @click="toggleDialog">
        <span>Правила продажи и возврата билетов</span>
        <v-dialog v-model="modelDialog" max-width="700" scrollable>
            <v-card>
                <v-card-title class="justify-center">ПРАВИЛА ПРИОБРЕТЕНИЯ И ВОЗВРАТА БИЛЕТОВ</v-card-title>
                <v-divider />
                <v-card-text>
                    <p class="text-center">Данное предложение является публичной офертой.</p>
                    <p>
                        Настоящая Оферта регламентирует условия и порядок продажи и возврата Билетов, а также права и обязанности,
                        возникающие в связи с этим у Продавца и Клиента.
                    </p>
                    <p>
                        Внимательно ознакомьтесь с текстом публичной оферты, и, если Вы не согласны с каким-либо пунктом оферты,
                        Вам предлагается отказаться от покупки и\или использования услуг, предоставляемых Продавцом.
                    </p>
                    <p>
                        Вы, как Клиент, подтверждаете свое право и дееспособность, финансовую состоятельность, а также сознаете
                        ответственность за обязательства, возложенные на вас в результате принятия настоящей Оферты.
                    </p>

                    <!-- 1 -->
                    <p><b>1. Определения</b>.</p>
                    <p>
                        1.1. <b>Продавец</b> – Акционерное общество «Торговый Дом ГУМ» (далее — «ГУМ») (109012, Красная площадь, д. 3,
                        комната 8, ОГРН 1027739098287).
                    </p>
                    <p>
                        1.2. <b>Клиент</b> – физическое сделкоспособное лицо, приобретающее Билет на получение права посещения ГУМ-Катка
                        в соответствии с приобретенным тарифом или имеющее такое намерение, либо лицо, которое приобрело Билет.
                    </p>
                    <p>
                        1.3. <b>ГУМ- Каток</b> – проект Продавца «ГУМ-Каток на Красной площади» 2024 - 2025гг. на территории, прилегающей
                        к зданию-памятнику «Верхние торговые ряды», выделенной для массового катания на коньках по адресу: г. Москва,
                        Красная площадь, д.3.
                    </p>
                    <p>
                        1.4. <b>Билет</b> – документ, удостоверяющий принадлежащее Клиенту право требования права посещения ГУМ-Катка в день
                        посещения (сеанса) и/или права пользования услугами проката спортивного инвентаря,  на которое был приобретен Билет.
                        Билет содержит индивидуальный номер, QR-код, а также информацию о наименовании услуги, Организаторе, стоимости. При
                        предъявлении Билета в распечатанном виде или на мобильном устройстве владелец Билета получает право посещения ГУМ-Катка.
                    </p>

                    <p>
                        1.5. <b>Электронный билет</b> – Билет, оплата которого производится Клиентом через Сайт, и направляемый Клиенту на
                        указанную им электронную почту. Далее по тексту именуется также Билет. Номинальная стоимость Билета (Номинальная
                        стоимость) – стоимость услуги по посещению ГУМ-Катка, устанавливаемая Продавцом, и по которой Продавец реализует Билет.
                    </p>

                    <p>
                        1.6. <b>Билет на Новогодний сеанс</b> – документ, удостоверяющий принадлежащее Клиенту право требования права посещения
                        ГУМ-Катка в день проведения Новогоднего сеанса 31.12.2024г. с 22:00 по 02:00 01.01.2025г. Билет содержит индивидуальный
                        номер, степень защиты (голограмму), информацию о наименовании услуги, Организаторе, стоимости. К Билету на Новогодний
                        сеанс прилагается буклет или флаер со схемой прохода на ГУМ-Каток и временным периодом, когда возможен проход на территорию
                        ГУМ-Катка. При предъявлении оригинального Билета владелец Билета получает право посещения ГУМ-Катка.
                    </p>

                    <p>
                        1.8. <b>Заказ</b> – сформированный согласно выбору Клиента заказ на приобретение Билета, содержащий данные о виде, количестве
                        Билетов, а также иных условиях приобретения. Совокупная стоимость заказа включает Номинальную стоимость выбранных Клиентом
                        Билетов.
                    </p>

                    <p>
                        1.7. <b>Пригласительный билет</b> – документ, удостоверяющий принадлежащее Клиенту право требования обмена на Билет.
                        Пригласительный билет содержит индивидуальный номер, QR-код, а также информацию о наименовании услуги, Организаторе,
                        условиях обмена, периоде действия.
                    </p>

                    <p>1.9. <b>Площадка</b> – ГУМ-Каток.</p>

                    <p>
                        1.10. <b>Услуги</b> – предоставление спортивных и развлекательных услуг в соответствии с Прейскурантом ГУМ-Катка на момент
                        оказания услуг.
                    </p>

                    <p>
                        1.11. <b>Оферта</b> – настоящая публичная оферта Продавца на реализацию Билетов Клиентам, опубликованная по электронному адресу в
                        сети Интернет https://www.gum-katok.ru.
                    </p>

                    <p>1.12. <b>Сайт</b> – официальный веб-сайт Продавца, размещенный по адресу - https://www.gum-katok.ru.</p>

                    <p>
                        1.13. <b>Личный кабинет</b> – персональный раздел Покупателя на сайте Продавца, в котором ему доступны некоторые отдельные
                        сервисы, формируемый после регистрации, доступ к которому предоставляется Клиенту в результате Авторизации. Использование
                        функций Личного кабинета предназначено для удобства Клиента и носит добровольный характер. Клиент вправе в любое время
                        прекратить использование Личного кабинета. Продавец не взимает плату за использование Личного кабинета.
                    </p>
                    <p>
                        1.14. <b>Регистрация</b> – совокупность действий Покупателя, включая предоставление Учетных данных и иной информации,
                        совершаемых Клиентом с использованием специальной формы пользовательского интерфейса сайте Продавца в целях формирования
                        Личного кабинета, получения доступа к дополнительным сервисам.
                    </p>
                    <p>
                        1.15. <b>Учетные данные</b> – уникальный логин, адрес электронной почты, номер телефона и пароль, создаваемые в процессе
                        Регистрации Клиента на сайте Продавца, используемые для доступа Клиента в Личный кабинет путем Авторизации. Учетные данные
                        являются конфиденциальными. Клиент обязан хранить имя (логин) и пароль в тайне и обеспечить их неразглашение иным лицам.
                    </p>
                    <p>
                        1.16. <b>Авторизация</b> – идентификация Клиентом посредством введения в соответствующие поля на сайте Продавца Учетных данных.
                    </p>
                    <p>
                        1.17. <b>Аннуляция бронирования</b> – удаление Билета из заказа Клиента с автоматическим переводом их в свободную продажу.
                        Если Билет не оплачен, то он аннулируется автоматически через 20 минут с момента оформления такого бронирования.
                    </p>

                    <!-- 2 -->
                    <p><b>2. Общие положения</b></p>
                    <p>
                        2.1. Настоящие Правила регулируют условия и порядок приобретения Билетов, как через Сайт, так и в кассах ГУМ-Катка. Рекомендуем
                        внимательно ознакомиться с настоящими Правилами до приобретения Билетов, и при возникновении каких-либо вопросов, связаться с
                        нами, так как оформление бронирования или покупки Билета означает безоговорочное согласие (акцепт) Клиента с настоящими Правилами (Офертой).
                    </p>
                    <p>
                        2.2. Информация о Продавце указывается на Сайте, на каждом Билете. Кроме того, при приобретении Билета в кассе ГУМ-Катка, Клиент имеет
                        возможность получить дополнительную информацию об услугах, оказываемых ГУМ-Катком еще до его оплаты, обратившись к ГУМ-Катка.
                    </p>
                    <p>
                        2.3. Клиент признает и соглашается, что в соответствии с законодательством Российской Федерации, конкретная услуга может быть отнесена
                        к числу тех, реализация Билета на которую, а равно проход, присутствие, могут быть запрещены для определенных категорий лиц.
                    </p>
                    <p>
                        2.4. Ответственным за оказание услуги является Продавец. Продавец не несет ответственности за несоответствие услуги
                        ожиданиям Клиента его субъективной оценке.
                    </p>
                    <p>
                        2.5. При приобретении Билетов Клиент должен принимать во внимание необходимость соблюдения требований (ограничений),
                        установленных Правилами посещения ГУМ-Катка. При этом Клиент обязан учитывать, что в случае нарушения указанных требований
                        владельцу Билета может быть отказано в оказании услуги без возврата стоимости Билета.
                    </p>
                    <p>
                        2.6. Перед приобретением Билета Клиент обязан ознакомиться с настоящими Правилами приобретения билетов, Порядком
                        возврата Билетов, Правилами посещения ГУМ-Катка, поскольку нарушение указанных правил может повлечь отказ в оказании
                        услуг Клиента, а также вправе ознакомиться с Политикой в отношении обработки персональных данных ГУМ. Оформление бронирования
                        или покупки Билета означает безоговорочное согласие Клиента с указанными правилами и условиями.
                        <br />
                        Продавец не несет ответственности в случае непосещения Клиентом ГУМ-Катка по обстоятельствам, не зависящим от Продавца.
                    </p>
                    <p>
                        2.7. Все претензии Клиентов, связанные с нарушениями законодательства Российской Федерации о защите прав потребителей при
                        оказании услуг, с качеством услуг, а также претензии и вопросы Клиентов относительно информации об услугах, содержащейся в
                        информационных и рекламных материалах, размещенных на Сайте, должны направляться Клиентами непосредственно Продавцу на электронный
                        адрес <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a>, info@gum.ru.  В случае поступления таких претензий Продавец
                        будет рассматривать претензии в соответствии с законодательством Российской Федерации.
                    </p>

                    <!-- 3 -->
                    <p><b>3. Билеты</b></p>
                    <p>
                        3.1. Билеты могут быть приобретены Клиентом через Сайт (<a href="https://www.gum-katok.ru" target="_blank">https://www.gum-katok.ru</a>), а также в кассах ГУМ-Катка.
                    </p>
                    <p>
                        3.2. Билеты на Новогодний сеанс могут быть приобретены Клиентом исключительно в кассах ГУМ-Катка.
                    </p>
                    <p>
                        3.3. Пригласительные билеты могут быть приобретены Клиентом исключительно в кассах ГУМ-Катка.
                        <b class="text-decoration-underline">
                            При этом продажа Пригласительных билетов, приобретаемых Клиентом в кассах
                            ГУМ-Катка, осуществляется от 10 шт. и  не более  100 шт.
                        </b>
                    </p>
                    <p>
                        3.4. Реализация Клиенту Билетов осуществляется исключительно для личных целей, не связанных с осуществлением Клиентом
                        предпринимательской деятельности. Клиент не вправе использовать Билет с целью рекламы своих товаров (работ, услуг),
                        товаров (работ, услуг) третьего лица, осуществлять перепродажу приобретенных Билетов в целях извлечения дохода от такой перепродажи.
                        Владение Билетом не дает права Клиенту изменять, копировать, использовать иным способом любые указанные на нем
                        обозначения/логотипы, товарные знаки, иные результаты интеллектуальной деятельности.
                    </p>
                    <p>
                        3.5. Обращаем внимание на то, что удаление какой-либо части Билета, изменение или повреждение Билета может привести к недействительности
                        Билета <br />
                        Продавец не несет ответственность в случае если приобретенный Билет поврежден, утерян и т.п.
                    </p>
                    <p>
                        3.6. Клиент несет все риски, связанные с копированием (посредством сканирования, фотографирования и т.п.) Билета,
                        с приобретением Билета у третьих лиц. Проход на Площадку будет разрешен первому, кто предъявил Билет, в связи с чем не
                        копируйте, не сканируйте, не фотографируйте и не выкладывайте фото/скан приобретенного Билета со QR-кодом в сети Интернет.
                    </p>
                    <p>
                        3.7. Просим внимательно проверять Билет при его получении и в случае наличия ошибок/некорректной информации незамедлительно сообщать Продавцу.
                    </p>
                    <p>
                        3.8. В случае приобретения Билетов через Сайт Клиенту необходимо распечатать Электронный билет на чистом белом листе формата
                        А4 или предъявить на экране мобильного устройства при входе на ГУМ-Каток.
                    </p>
                    <p>
                        3.8.1. Электронный билет может быть распечатан на цветном или на черно-белом, на лазерном, или на струйном принтере.
                        При этом качество печати должно быть не ниже 300 Dpi. Картинка на экране мобильного устройства должна быть хорошего качества.
                    </p>
                    <p>
                        3.8.2. Гарантом уникальности Электронного билета являются штрих-код (или qr-код). Оказание услуги Клиенту возможно, только при
                        условии, что штрих-код/qr-код считывается сканирующим устройством.
                    </p>
                    <p>
                        3.8.3.	Электронный билет с исправлениями и помарками недействителен.
                    </p>

                    <!-- 4 -->
                    <p><b>4. Стоимость Билета и оплата.</b></p>
                    <p>4.1. Стоимость Билета устанавливается Продавцом и указывается на Билете.</p>
                    <p>4.2. Общая стоимость Билетов формируется и отображается в Заказе Клиента.</p>
                    <p>4.3. Билет может быть получен Клиентом только после полной оплаты соответствующего Заказа.</p>
                    <p>4.4. Оплата Билетов может осуществляться:</p>
                    <ul class="mb-2">
                        <li>
                            в кассах за наличный расчет, а также с использованием платежных карт, которые принимаются на территории РФ,
                            оплата по Биометрии и QR-коду не осуществляется
                        </li>
                        <li>
                            через Сайт путем безналичного перечисления денежных средств с использованием платежных карт, которые принимаются
                            на территории РФ;
                        </li>
                    </ul>
                    <p>
                        Для оплаты (ввода реквизитов вашей карты) вы будете перенаправлены на платежный шлюз ПАО Сбербанк. Соединение с платежным
                        шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если
                        ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept,
                        J-Secure  или иной аналогичной системы поддерживаемой на территории РФ, для проведения платежа также может потребоваться ввод
                        специального пароля.
                    </p>
                    <p>
                        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается шлюз
                        ПАО Сбербанк. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ.
                    </p>
                    <p>4.5. Оплата Билетов на Новогодний сеанс может осуществляться:</p>
                    <ul class="mb-2">
                        <li>
                            в кассах за наличный расчет, а также с использованием платежных карт, которые принимаются на территории РФ,
                            оплата по Биометрии и QR-коду не осуществляется
                        </li>
                    </ul>
                    <p>4.6. Оплата Пригласительных билетов может осуществляться:</p>
                    <ul class="mb-2">
                        <li>
                            в кассах за наличный расчет, а также с использованием платежных карт, которые принимаются на территории РФ,
                            оплата по Биометрии и QR-коду не осуществляется
                        </li>
                    </ul>
                    <p>
                        4.7. Продажа Билетов на Новогодний сеанс начинается не раннее 15 декабря 2024г.
                    </p>
                    <p>
                        4.8. Продажа Билетов прекращается:
                        <ul class="mb-2">
                            <li>
                                в кассах через 15 (пятнадцать) минут после начала сеанса;
                            </li>
							<li>
                                на Сайте за 1 час 30 минут до начала сеанса.
                            </li>
                        </ul>
                    </p>
                    <!-- 5 -->
                    <p><b>5. Бронирование Билетов</b></p>
                    <p>5.1. Клиент может забронировать Билет через Сайт.</p>
                    <p>5.2. Бронирование Билета на Сайте прекращается за 1 час 30 минут до начала сеанса.</p>
                    <p>5.3. Срок действия бронирования составляет 20 (двадцать) минут.</p>
                    <p>
                        5.4. ППродавец вправе аннулировать бронирование Билетов тех лиц, которые указали неверные данные (имя, фамилию, адрес e-mail, 
						контактный телефон, и т.п.). <br />
                        Продавец не несет ответственности в случае неисполнения или ненадлежащего исполнения услуг ГУМ-Катка, возникшего из-за 
						недостоверности, недостаточности или несвоевременности подтверждающих сведений, предоставленных Клиентом, а также 
						возникшего вследствие других нарушений условий настоящей Оферты со стороны Клиента. Клиент принимает и соглашается с тем, 
						что он в полной мере несет ответственность за сообщение ложного или недействительного электронного почтового адреса.
                    </p>
                    <p>
                        5.5. Продавец вправе аннулировать бронирование Билетов при наличии подозрений в том, что данные Клиента были неправомерно 
						использованы третьими лицами, в том числе для оплаты бронирования Билетов. Продавец вправе аннулировать бронирование Билетов 
						и в других мотивированных случаях.
                    </p>
                    <p>
                        5.6. При аннуляции оплаченного Билета, Номинальная стоимость аннулированного Билета возвращается Клиенту. Возврат стоимости 
						денежных средств после аннулирования осуществляется в срок, установленный банком Клиента.
                    </p>
                    <p>5.7. Выкупить забронированный Билет можно в течение срока действия бронирования (п.5.2 настоящих Правил).</p>
					<p>5.8. Продавец не осуществляет бронирование Билетов на Новогодний сеанс и Пригласительных билетов.</p>

                    <!-- 6 -->
                    <p><b>6. Порядок возврата Билетов.</b></p>
                    <p>
                        6.1. Возврат Билетов и Номинальной стоимости Билетов по любым основаниям осуществляется Продавцом в соответствии с настоящим 
						разделом, установленными Продавцом (порядок, срок, адрес возврата билетов и т.п.). Правила возврата размещены на сайте 
						Продавца (<a href="www.gum-katok.ru">www.gum-katok.ru</a>).
                    </p>
					<p>
						6.2. Билет, полученный в кассе ГУМ-Катка в результате обмена Пригласительного билета, возврату и обмену не подлежит.
					</p>
                    <p>
                        6.3. Возврат Электронных билетов, приобретенных на сайте <a href="www.gum-katok.ru/">www.gum-katok.ru/</a>, а также билетов, 
						приобретенных в кассе ГУМ-Катка, <b>возможен не позднее чем за 30 минут до начала сеанса на который был приобретен билет</b>, при условии, 
						что проход по Билетам не был осуществлен.
                    </p>
                    <p>
                        6.4. Возврат Электронных билетов, приобретенных на сайте <a href="www.gum-katok.ru/">www.gum-katok.ru/</a> или Билетов,
                        приобретенных в Кассе ГУМ-Катка, <b>позднее чем за 30 минут до начала сеанса на который был приобретен билет</b>, возможен
                        только при наличии листка нетрудоспособности / справки от врача, выданных медицинской организацией на дату сеанса. Для
                        оформления подобного возврата электронного билета отправьте с электронной почты, указанной при регистрации аккаунта в личном
                        кабинете, на электронный адрес <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a>: билет, кассовый чек, уведомление из
                        банка о списании денежных средств, лист нетрудоспособности / справку от врача.
                    </p>
                    <p>
                        6.5. Возврат неиспользованного Электронного билета производится при обращении на электронный адрес
                        <a href="mailto: info.katok@gum.ru">info.katok@gum.ru</a>. Для оформления возврата электронного билета отправьте с электронной
                        почты, указанной при регистрации аккаунта в личном кабинете необходимо указать номер заказа, номер билета (если возвращается
                        не полный заказ).
                    </p>
                    <p>
                        6.6. Для оформления возврата неиспользованного Билета, приобретенного в кассе ГУМ-Катка (далее Касса), нужно обратиться в 
						Кассу, заполнить заявление о возврате, приложив к нему билет и кассовый чек, уведомление из банка о списании денежных средств 
						(банковский слип либо справка по операции) – в случае оплаты безналичными средствами, при этом время до начала сеанса должно 
						быть не менее 30 минут.
                    </p>
                    <p>
                        6.7. Для оформления возврата Билета на Новогодний сеанс, приобретенного в кассе ГУМ-Катка, нужно обратиться в Кассу, заполнить 
						заявление о возврате, приложив к нему билет и кассовый чек, уведомление из банка о списании денежных средств (банковский слип 
						либо справка по операции) – в случае оплаты безналичными средствами, при этом время до начала сеанса должно быть не менее 3-х дней.
                    </p>
                    <p>
                        6.8. Для оформления возврата Пригласительного билета, приобретенного в кассе ГУМ-Катка, нужно обратиться в Кассу, заполнить 
						заявление о возврате, приложив к нему билет и кассовый чек, уведомление из банка о списании денежных средств (банковский слип 
						либо справка по операции) – в случае оплаты безналичными средствами, при этом время до окончания действия Пригласительного 
						билета должно быть не менее 1 дня.
                    </p>
                    <p>
                        6.9. Возврат денежных средств, составляющих Номинальную стоимость Билета, осуществляется исключительно тому Клиенту, который 
						приобрел Билет непосредственно у Продавца. При оплате Билетов с помощью платежной карты возврат денежных средств производится 
						исключительно на ту платежную карту, с которой была произведена оплата.<br />
						Решения по вопросам возмещения затрат Клиента, связанных с оказанием услуг ГУМ-Катка, принимает исключительно Продавец и 
						несет ответственность за последствия принятого решения.
                    </p>
                    <p>
                        6.10. Зачисление причитающихся денежных средств за неиспользованный Билет осуществляется тем же средством электронного платежа, 
						которым был получен платеж и на те же реквизиты счета, с которого производилось оплата Покупателем не позднее 30-ти рабочих дней 
						с момента оформления возврата.
                    </p>
					<p>
						6.11. В случае приостановления и/или прекращения работы ГУМ-Катка по техническим или профилактическим причинам, Продавец 
						оповещает Клиентов путем отправки на указанные Клиентом телефонный номер и/или адрес электронный почты соответствующего сообщения. 
						При этом денежные средства за электронные билеты возвращаются тем же средством электронного платежа, которым был получен платеж и 
						на те же реквизиты счета, с которого производилась оплата Клиентом не позднее 30-ти рабочих дней с момента оповещения. Возврат 
						денежных средств за билеты, приобретённые в кассе описан в пункте 6.5. настоящих правил.
					</p>
					<p>
						6.12. Ответственность за неисполнение или ненадлежащее исполнение обязательства по оказанию услуги по посещению ГУМ-Катка и 
						оказанию соответствующей услуги в полном объеме (в том числе в соответствии с Законом Российской Федерации от 07.02.1992  
						№ 2300-1 «О защите прав потребителей») несет Продавец.
					</p>

                    <p><b>7. Согласие на обработку персональных данных. </b></p>
                    <p>
                        7.1. Клиент настоящим дает свое согласие на обработку своих персональных данных, которые необходимы для корректного документального 
						оформления правоотношений между Клиентом и Продавцом в целях продажи, возврата Билетов на оказание услуг ГУМ-Катка, а также на 
						осуществление любых действий в отношении персональных данных, которые необходимы или желаемы для достижения указанных выше целей, 
						включая (без ограничения) сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, 
						распространение (в том числе передачу третьим лицам), обезличивание, блокирование персональных данных, а также осуществление 
						любых иных действий с персональными данными, предусмотренных действующим законодательством Российской Федерации.
                    </p>
                    <p>
                        7.2. Продавец гарантирует, что обработка персональных данных Клиента осуществляется в соответствии с Положением об обработке 
						персональных данных ГУМ, Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и иным действующим законодательством 
						РФ о защите персональных данных.
                    </p>
                    <p>
                        7.3. Согласие на обработку персональных данных действует с момента акцепта Оферты (настоящих Правил) Клиентом и действует до 
						истечения сроков, установленных действующим законодательством Российской Федерации.
                    </p>

                    <p><b>8. Применимое право.</b></p>
                    <p>
                        В отношении настоящих Правил, а также прав и обязанностей, которые возникают в связи с приобретением Билетов, Билетов на 
						Новогодний сеанс, Пригласительных билетов подлежит применению материальное и процессуальное право Российской Федерации, 
						компетентный суд – суд Российской Федерации по месту нахождения Продавца  (договорная подсудность) в соответствии с 
						правилами о подсудности спора.
                    </p>
                    <p><b>9. Изменения настоящих Правил.</b></p>
                    <p>
                        9.1. Настоящие Правила (Оферта) вступают в силу с момента их размещения на Сайте Продавца и действует до момента размещения 
						новой редакции Правил (т.е. Правил с внесенными изменениями и/или дополнениями) на Сайте Продавца.
                    </p>
                    <p>
                        9.2. Продавец вправе в любое время вносить изменения и/или дополнения в настоящие Правила (Оферту), но в любом случае такие 
						изменения публикуются и доводятся до всеобщего сведения посредством опубликования на Сайте. Использование Клиентом услуг 
						Продавца после внесения изменений в текст настоящих Правила (Оферты) означает акцепт Правил (Оферты) с учетом внесенных изменений.
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="modelDialog = false">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script lang="ts">
    import Vue from 'vue';
    import Component from 'vue-class-component';
    @Component
    export default class ButtonOferta extends Vue {
        modelDialog = false;
        toggleDialog() {
            this.modelDialog = true;
        }
    }
</script>
