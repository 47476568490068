<template>
	<v-dialog v-model="SiteDisabled" persistent fullscreen>
		<div class="dialog-disabled fill-height justify-center d-flex align-center">
			<v-card class="mx-4" max-width="480">
				<v-card-title class="logo-title">
					<ImageLogo class="mx-auto" />
				</v-card-title>
				<v-card-text class="text-h5 py-4 px-5 text-center primary--text" v-html="DisabledPlaceholder"></v-card-text>
			</v-card>
		</div>
	</v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import ImageLogo from './ImageLogo.vue';
export default Vue.extend({
	components: {
		ImageLogo,
	},
	name: 'DialogDisabled',
	data: () => ({
		SiteDisabled: false,
		DisabledPlaceholder: '',
	}),
	created() {
		if (location.hostname !== 'localhost' && !this.isIp && !location.hostname.includes('static')) {
			fetch('https://gum-katok.ru/api/settings/')
				.then((data) => data.json())
				.then((data) => {
					this.SiteDisabled = Boolean(parseInt(data.disabled));
					this.DisabledPlaceholder = data.disabledPlaceholder;
				});
		}
	},
	computed: {
		isIp: {
			get() {
				return location.hostname.match(/\d*\.\d*\.\d*\.\d*./);
			},
		},
	},
});
</script>

<style lang="less" scoped>
.dialog-disabled {
	background: rgba(0, 0, 0, 0.5);

	.text-h5 {
		color: #013b9f;
	}

	.logo-title {
		position: relative;
		top: -86px;
		margin-bottom: -120px;
	}
}
</style>
