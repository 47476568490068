<template>
	<v-row id="section-schedule" class="section-schedule" max-width="100%">
		<v-col md="8" sm="12" class="d-flex align-start">
			<div class="px-3">
				<p class="text-h4 my-4 text-center primary--text">Расписание</p>

				<!-- Таблица сеансов -->
				<data-table />
				<p class="primary--text">
					Электронный билет необходимо распечатать и предъявить при входе на каток.
				</p>
				<v-alert type="warning">
					
						Уважаемые посетители, мы снова начали получать сообщения о работе сайтов-двойников: мошенники создают страницы, которые внешне
						напоминают официальный сайт ГУМ-катка. В том числе, на некоторых предлагают купить билеты якобы на сеансы катания.
						<v-divider></v-divider>
						Руководство ГУМ-Катка официально заявляет, что имеет только один сайт
						<a class="primary--text font-weight-bold text-decoration-none" href="https://gum-katok.ru/">https://gum-katok.ru</a>
				</v-alert>
			</div>
		</v-col>

		<v-col cols="12" md="4" sm="12" class="info-sale">
			<div class="section-schedule__sale pa-3 mx-sm-4">
				<p class="title text-center text-h5 font-weight-bold primary--text">Цены</p>
				<schedule-prices />
				<v-img :src="Sale" class="mb-3 mx-auto" />
				<ButtonRules />
				<ButtonRent />
				<ButtonOferta />
			</div>
		</v-col>
	</v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import DataTable from './Generic/DataTable.vue';
import SchedulePrices from './SchedulePrices.vue';
import ButtonRules from './buttons/ButtonRules.vue';
import ButtonOferta from './buttons/ButtonOferta.vue';
import ButtonRent from './buttons/ButtonRent.vue';

export default Vue.extend({
	components: { DataTable, SchedulePrices, ButtonRules, ButtonRent, ButtonOferta },
	name: 'SectionSchedule',
	data: () => ({
		Sale: require('./../assets/images/2024/sale.png'),
		DialogRules: false as boolean,
		DialogRental: false as boolean,
	}),
});
</script>

<style lang="less" scoped>
.section-schedule {
	img {
		max-width: 100%;
	}

	.info-sale {
		position: relative;
		z-index: 1;

		.section-schedule__sale {
			border: 1px solid #013b9f;
			display: flex;
			flex-direction: column;

			.section-schedule__prices {
				p[data-active='true'] {
					font-weight: bold;
				}
			}
		}

		& > .v-image {
			position: absolute;
			bottom: 0;
			right: 0;
			transform: translateX(47.5%) translateY(80%);
		}
	}
}
</style>
