<template>
	<div class="section-infopartners primary--text py-2 pt-16 my-16">
		<p class="text-h5 font-weight-bold my-6 text-center">Инфопартнёры</p>
		<v-row class="align-center justify-center">
			<v-col cols="5" sm="2" v-for="(Item, index) in Items" :key="index">
				<v-img :src="Item" contain max-width="200" max-height="64" class="mx-auto" />
			</v-col>
		</v-row>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionInfopartners',
	data: () => ({
		Items: [
			require('@/assets/images/infopartners/2023/001.svg'),
			require('@/assets/images/infopartners/2023/003.svg'),
			require('@/assets/images/infopartners/2023/002.svg'),
		],
	}),
});
</script>
