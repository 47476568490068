import GUMProvider from "./gum-provider";
import IGUMProvider from "./i-gum-provider";
import IGUMService from "./i-gum-service";

export default class GUMService implements IGUMService {
    private _provider: IGUMProvider;

    constructor() {
        this._provider = new GUMProvider();
    }

    async GetSessions(date: string, mode = "json"): Promise<Array<object>> {
        const r = await this._provider.GetSessions(date, mode);
        return r;
    }
}