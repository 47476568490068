<template>
	<div class="d-flex flex-column">
		<v-dialog v-for="(Item, index) in PriceDates" :key="index" max-width="800" v-model="Active[index]">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					width="100%"
					large
					max-width="600"
					class="mb-4 rounded-xl mx-auto"
					:color="Item.Active ? 'primary' : null"
					v-on="on"
					v-bind="attrs"
					v-show="Item.Visible"
					:data-active="Item.Active"
				>
					с {{ Item.Format() }} <br />
					по {{ Item.Format(true) }}
				</v-btn>
			</template>

			<v-card class="section-schedule__prices">
				<v-card-title>
					<span>с {{ Item.Format() }} по {{ Item.Format(true) }}</span>
					<v-spacer />
					<v-btn fab small color="primary" @click="$set(Active, index, false)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-alert v-if="!Item.Data" prominent type="error">Не удалось загрузить данные по ценам</v-alert>
					<template v-else>
						<p class="title text-center">Цены</p>

						<div class="d-flex justify-space-between font-weight-bold">
							<div class="f1">Наименование услуги</div>
							<div class="d-flex justify-space-between f1 align-end">
								<div class="f2 text-center">
									<span>
										Будние дни <br />
										<div style="opacity: 0">space</div>
									</span>
									<div class="d-flex justify-space-around">
										<span>{{ dayStart }} - 14:30</span>
										<span>15:00 - {{ dayEnd }}</span>
									</div>
								</div>
								<div class="f1 text-center">
									<span>
										Выходные и <br />
										праздничные дни
									</span>
									<div>{{ dayStart }} -{{ dayEnd }}</div>
								</div>
							</div>
						</div>

						<template v-if="Item.Data?.items">
							<div v-for="(service, index) in Item.Data.items" :key="index" class="service">
								<div class="f1">
									{{ service.title }}
									<template v-if="service.type != undefined">
										<br />
										{{ AgeRange[service.type] }}
									</template>
								</div>

								<template v-if="service.prices !== undefined">
									<div class="d-flex justify-space-around f1 text-center">
										<template v-if="Array.isArray(service.prices)">
											<span v-for="(price, pindex) in service.prices" :key="pindex" class="f1">
												{{ price ? price + ' ₽' : 'Бесплатно' }}
											</span>
										</template>
										<template v-else-if="service.prices === null"> Бесплатно, при наличии свободных билетов на сеанс </template>
										<template v-else>
											<template v-if="service.prices == -1">
												Бесплатно, при условии приобретения взрослого билета катания на льду для сопровождения ребенка на
												ледовое поле
											</template>
											<template v-else>
												<span>{{ service.prices }}</span>
											</template>
										</template>
									</div>
								</template>
							</div>

							<v-alert type="info" prominent v-if="index != 2">
								Цена на билет на Новогоднюю ночь - {{ 10000 + yearNext }} ₽. <br />
								Сеанс: с 22:00 31.12.{{ yearCurrent }} до 02:00 01.01.{{ yearNext }}
							</v-alert>
						</template>
					</template>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script lang="ts">
import { DATE_SALE_END, DATE_SALE_START } from './../main';
import PriceDate from './../codebase/PriceData';
import Vue from 'vue';

export default Vue.extend({
	name: 'SchedulePrices',
	data: () => ({
		Active: [],
		yearCurrent: DATE_SALE_START.getFullYear(),
		yearNext: DATE_SALE_END.getFullYear(),
		dayStart: DATE_SALE_START.toLocaleTimeString().slice(0, 5),
		dayEnd: DATE_SALE_END.toLocaleTimeString().slice(0, 5),
		AgeRange: [
			'взрослый билет',
			'детский билет (от 7 до 12 лет)',
			'для пенсионеров (при предъявлении удостоверения или социальной карты), участников СВО и членов их семей (при предъявлении соответствующих документов)',
			'для детей дошкольного возраста (до 7 лет)',
			'для посетителей старше 75 лет (при предъявлении удостоверения личности)',
			'для пенсионеров',
		] as Array<string>,
	}),
	computed: {
		PriceDates: {
			get(): Array<PriceDate> {
				return [
					new PriceDate(`${this.yearCurrent}/12/06`, `${this.yearCurrent}/12/24`),
					new PriceDate(`${this.yearCurrent}/12/25`, `${this.yearNext}/01/08`),
					new PriceDate(`${this.yearNext}/01/09`, `${this.yearNext}/03/09`),
				];
			},
		},
	},
	created(): void {
		//
	},
	async mounted(): Promise<void> {
		this.PriceDates.forEach((entry: PriceDate) => {
			let date = entry.End.toLocaleDateString('ru-RU');
			fetch(`https://gum-katok.ru/api/?date=${date}`)
				.then((data) =>
					data.json().then((data) => {
						entry.Data = data;
						//console.log(entry.Data);
					})
				)
				.catch((error) => {
					console.error(error);
				});
		});
	},
});
</script>

<style lang="less" scoped>
.section-schedule__prices {
	.f1 {
		flex: 1;
	}

	.f2 {
		flex: 2;
	}

	.service {
		display: flex;
		justify-content: space-around;
		padding-block: 12px;
		align-items: center;

		&:not(:first-child) {
			border-top: 1px solid black;
		}
	}
}

@media (max-width: 600px) {
	.section-schedule__prices {
		p,
		span,
		div {
			line-height: normal;
			font-size: 10px;
		}
	}
}
</style>
