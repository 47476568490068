<template>
	<svg
		v-if="!small"
		:style="cssProps()"
		width="155"
		class="image-logo-1"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 100 104.6"
		style="enable-background: new 0 0 100 104.6"
		xml:space="preserve"
	>
		<g id="Слой_2_00000043421557727081227670000010869762150408566183_">
			<g id="Layer_1">
				<path
					class="st0"
					d="M73.6,13.3V9.9c0,0-8.6-7.4-23.6-7.4S26.8,8.9,26.8,8.9v4.2C3.9,25.9-4.3,54.9,8.5,77.8s41.8,31.1,64.7,18.3
			s31.1-41.8,18.3-64.7C87.3,23.9,81.1,17.6,73.6,13.3"
				/>
				<g>
					<path
						class="st1"
						d="M72.6,16.2v2.5c20,12.3,26.3,38.4,14.1,58.4s-38.4,26.3-58.4,14.1S1.9,52.8,14.1,32.8
				c3.6-5.9,8.5-10.8,14.4-14.3V16C7.1,28-0.6,55.2,11.5,76.7s39.2,29.1,60.7,17.1s29.1-39.2,17.1-60.7
				C85.4,26.1,79.6,20.2,72.6,16.2"
					/>
					<path
						class="st1"
						d="M28.6,25.4v-1.8C15.9,32.4,9.7,48,13,63.1c0,0.4,0.4,0.7,0.8,0.7c0.4,0,0.7-0.4,0.7-0.8c0,0,0-0.1,0-0.1
				C11.4,48.6,16.9,34,28.6,25.4"
					/>
					<path
						class="st1"
						d="M72.6,23.8v1.8c11.3,8.5,16.7,22.8,13.8,36.7c-0.1,0.4,0.2,0.8,0.6,0.9c0.4,0.1,0.8-0.2,0.9-0.6l0,0
				c0.5-2.5,0.8-5.1,0.8-7.7C88.6,42.6,82.6,31,72.6,23.8"
					/>
					<path
						class="st1"
						d="M72.6,31.1v3c11.5,12.2,11,31.5-1.3,43.1s-31.5,11-43.1-1.3C17,64,17.2,45.4,28.6,33.7v-3
				c-13.4,12-14.4,32.7-2.4,46s32.7,14.4,46,2.4s14.4-32.7,2.4-46C74,32.4,73.3,31.7,72.6,31.1"
					/>
					<polyline
						class="st1"
						points="16,68.1 19,69.2 19.6,70.7 16.5,69.4 14.4,73 13.8,71.6 15.4,69 14.7,68.7 12.9,69.4 12.4,68.3 
				17.7,66.1 18.1,67.2 16,68.1 			"
					/>
					<path
						class="st1"
						d="M18.4,73.9l0.6,0.9c0.2,0.4,0.7,0.6,1.1,0.4c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,0.5-0.7,0.1-1.3L19.9,73
				L18.4,73.9 M15.9,75.5l-0.6-1l4.8-3l1.4,2.2c0.5,0.7,0.4,1.8-0.4,2.3c-0.1,0.1-0.2,0.1-0.2,0.2c-0.8,0.6-1.9,0.3-2.4-0.5
				c0,0-0.1-0.1-0.1-0.1l-0.7-1.2L15.9,75.5z"
					/>
					<path
						class="st1"
						d="M21.2,79.3l1,1.2l1.2-2.1l0,0L21.2,79.3 M24.9,78.1l-3,5.3l-0.8-1l0.6-1.1l-1.4-1.6l-1.1,0.5l-0.8-0.9
				l5.6-2.3L24.9,78.1z"
					/>
					<path
						class="st1"
						d="M28.4,83.9c0.3-0.5,0.2-1.2-0.3-1.6c-0.5-0.4-1.4-0.6-2.4,0.7c-0.7,0.8-0.9,1.8-0.1,2.4
				c0.5,0.4,1.2,0.4,1.7,0l0.9,0.8c-0.9,0.9-2.3,0.9-3.3,0.1c-1.2-1-1.4-2.5-0.1-4s2.8-1.6,3.9-0.7c1,0.7,1.3,2.1,0.6,3.1L28.4,83.9
				"
					/>
					<polyline
						class="st1"
						points="31.3,86.3 33.2,87.4 34.3,85.5 35.4,86.1 32.5,91 31.5,90.4 32.8,88.2 30.8,87.1 29.5,89.3 
				28.5,88.8 31.4,83.8 32.4,84.4 31.3,86.3 			"
					/>
					<path
						class="st1"
						d="M36.8,90.5l1.4,0.5v-2.4l0,0L36.8,90.5 M39.4,87.7l-0.1,6.1l-1.2-0.4l0-1.3l-2-0.7l-0.8,1l-1.2-0.4l3.9-4.7
				L39.4,87.7z"
					/>
					<path
						class="st1"
						d="M45.2,90.1l-1.5-0.3c-0.4-0.1-0.8,0-1,0.4c0,0,0,0.1,0,0.1c-0.1,0.5,0.2,0.8,0.8,0.9l1.4,0.2L45.2,90.1 M45.6,95l-1.2-0.2l0.4-2.2l-0.9-0.2l-1.7,2l-1.4-0.2l1.8-2.2c-0.7-0.3-1.1-1.1-1-1.8c0.2-0.9,1.1-1.6,2-1.4c0,0,0,0,0.1,0
				l2.8,0.5L45.6,95z"
					/>
					<polyline class="st1" points="55.4,89.2 55.8,94.9 54.6,95 54.3,90.3 52,90.4 52.4,95.2 51.2,95.3 50.8,89.6 55.4,89.2 			" />
					<path
						class="st1"
						d="M61.1,87.9l1.4,5.6l-1.2,0.3l-1.1-4.6l-1.6,0.4l0.6,2.4c0.3,1.4,0.2,2.5-0.9,2.7c-0.2,0.1-0.5,0.1-0.7,0.1
				l-0.2-0.9c0.1,0,0.2,0,0.3,0c0.5-0.1,0.6-0.5,0.3-1.7l-0.8-3.2L61.1,87.9"
					/>
					<path
						class="st1"
						d="M66.6,91c0.6-0.3,1.3-1,0.6-2.5s-1.7-1.5-2.2-1.2s-1.3,1-0.6,2.5C65,91.2,66,91.2,66.6,91 M64.6,86.4
				c1.5-0.8,3.3-0.3,4.1,1.2s0.3,3.3-1.2,4.1c-1.5,0.8-3.3,0.3-4.1-1.2c0-0.1-0.1-0.1-0.1-0.2c-0.8-1.2-0.5-2.9,0.8-3.7
				C64.2,86.5,64.4,86.4,64.6,86.4L64.6,86.4z"
					/>
					<polyline
						class="st1"
						points="71.6,88.2 72.9,87.3 70.1,83.4 71.1,82.8 73.8,86.6 75.1,85.7 72.3,81.9 73.3,81.2 76,85 
				76.5,84.7 77.8,86.5 76.8,87.1 76.2,86.2 71.2,89.7 67.9,85 68.9,84.3 71.6,88.2 			"
					/>
					<path
						class="st1"
						d="M77.9,81.5l1-1.1l-2.2-1l0,0L77.9,81.5 M76.3,78l5.5,2.4l-0.9,0.9l-1.1-0.5l-1.4,1.5l0.6,1.1l-0.9,0.9
				L75.3,79L76.3,78z"
					/>
					<path
						class="st1"
						d="M84.1,75.6l-3.1-2.1l-0.9,1.3l0.7,0.4c0.9,0.5,1.7,1.2,2.2,2.1L84.1,75.6 M80.8,72l4,2.6l0.3-0.5l1.8,1.2
				l-0.6,0.9l-1-0.7l-1.8,2.8l1,0.7L83.8,80L82,78.8l0.3-0.4c-0.5-1-1.3-1.8-2.3-2.2l-1.4-0.9L80.8,72z"
					/>
					<path
						class="st1"
						d="M86.3,71.5l0.5-1.2c0.2-0.4,0.1-0.9-0.3-1.1c0,0-0.1,0-0.1,0c-0.5-0.2-0.8,0-1.1,0.5l-0.5,1.2L86.3,71.5 M83.9,70.4l0.6-1.4c0.3-0.9,1.3-1.5,2.2-1.2c0.1,0,0.1,0,0.2,0.1c0.9,0.4,1.3,1.4,0.9,2.3c0,0.1-0.1,0.2-0.1,0.2l-1.1,2.4
				l-5.2-2.3l0.5-1.1L83.9,70.4z"
					/>
					<path
						class="st1"
						d="M58.4,77.4c-0.4,0.4-0.9,0.6-1.5,0.5c-0.7,0.1-1.3-0.2-1.7-0.8c-0.2-0.3-0.3-0.7-0.3-1.1h1.1
				c0,0.2,0,0.4,0.1,0.6c0.1,0.3,0.4,0.4,0.7,0.4c0.4,0,0.8-0.3,0.8-0.8c0,0,0-0.1,0-0.1c0-0.3-0.2-0.6-0.5-0.7
				c-0.3-0.1-0.5-0.1-0.8-0.1v-0.8c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.4,0.3-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.7h-1c0-0.3,0.1-0.5,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.2,0.5-0.3
				c0.2-0.1,0.5-0.1,0.7-0.1c0.5,0,0.9,0.1,1.3,0.4c0.3,0.3,0.5,0.7,0.5,1.1c0,0.3-0.1,0.6-0.3,0.8c-0.1,0.1-0.2,0.2-0.4,0.3
				c0.2,0,0.3,0.1,0.4,0.3c0.3,0.3,0.4,0.7,0.4,1.1C58.9,76.6,58.7,77.1,58.4,77.4 M54,76.6c-0.2,0.8-1,1.3-1.8,1.3
				c-0.4,0-0.8-0.1-1.2-0.4c-0.3-0.3-0.6-0.7-0.6-1.1h1.1c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.4,0,0.7-0.3,0.8-0.6
				c0.1-0.3,0.1-0.7,0.2-1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.5,0.2-0.8,0.2c-0.4,0-0.9-0.2-1.2-0.5c-0.4-0.3-0.6-0.8-0.5-1.3
				c0-0.5,0.2-1.1,0.5-1.4c0.7-0.8,2-0.8,2.7,0c0.2,0.2,0.3,0.4,0.4,0.6c0.2,0.5,0.3,1.1,0.3,1.7C54.3,75.5,54.2,76.1,54,76.6z M47.8,54.8v-1.5h2.3v1.5H47.8z M48.9,77.4c-0.4,0.3-0.9,0.5-1.5,0.5c-0.5,0-1-0.1-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.2
				c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.4-0.5,0.6-0.6c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.2-0.7,0.5-1
				c0.4-0.3,0.8-0.5,1.3-0.4c0.5,0,0.9,0.1,1.3,0.4c0.3,0.3,0.5,0.6,0.5,1c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.4-0.5,0.5
				c0.3,0.1,0.5,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.9C49.5,76.7,49.3,77.1,48.9,77.4z M45.2,54.8v-1.5h2.3v1.5H45.2z M42.5,54.8
				v-1.5h2.3v1.5H42.5z M44.1,77.8H43v-3.9h-1.3v-0.8c0.2,0,0.5,0,0.7-0.1c0.4-0.1,0.7-0.3,0.8-0.7c0-0.1,0-0.1,0-0.2h0.9L44.1,77.8
				z M50.5,54.8v-1.5h2.3v1.5H50.5z M53.2,54.8v-1.5h2.3v1.5H53.2z M55.9,54.8v-1.5h2.3v1.5H55.9z M74.7,64.2l-3.2,3.1h-1.2v-2.5
				L67.7,55v-0.1h0.6v-0.7h-0.6v-3.9h0.6v-0.7h-0.6l-1.3-7.9v-2.6h2.9l-1-1l1-1h-2.9v-0.6h-0.3v0.6l0,0v2l0,0v2.6l-1.3,7.9h-0.6v0.7
				h0.6v3.9h-0.6v0.7h0.6V55l-2,7.6l-3.3-6.5l0.7-1.3h-1.2v-2.4h-0.7v0.5H42.5v-0.5h-0.7v2.4h-1.2l0.7,1.3l-3.3,6.5l-2-7.6v-0.1h0.6
				v-0.7h-0.6v-3.9h0.6v-0.7h-0.6l-1.3-7.9v-2.6h2.9l-1-1l1-1h-2.9v-0.6h-0.3v5.2L33,49.6h-0.6v0.7H33v3.9h-0.6v0.7H33V55l-2.6,9.8
				v2.5h-1.2L26,64.2l-2.2,2.1c6.4,14.6,23.4,21.3,38.1,14.9c6.7-2.9,12-8.3,14.9-14.9L74.7,64.2z"
					/>
					<path
						class="st1"
						d="M52.2,73c-0.2,0-0.3,0.1-0.5,0.2c-0.2,0.2-0.4,0.5-0.3,0.8c0,0.3,0,0.5,0.2,0.8c0.2,0.2,0.4,0.3,0.6,0.2
				c0.2,0,0.3,0,0.4-0.1c0.3-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.5-0.2-0.8C52.7,73.1,52.5,73,52.2,73"
					/>
					<path
						class="st1"
						d="M47.5,74.4c0.4,0,0.7-0.2,0.7-0.6c0,0,0-0.1,0-0.1c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2
				c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5C46.8,74.1,47.1,74.4,47.5,74.4C47.5,74.4,47.5,74.4,47.5,74.4"
					/>
					<path
						class="st1"
						d="M47.5,75.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.2,0.1,0.5,0.2,0.7c0.3,0.3,0.9,0.3,1.2,0
				c0.3-0.4,0.3-0.9,0-1.3C48,75.3,47.7,75.2,47.5,75.3"
					/>
					<path
						class="st1"
						d="M42,10.1l0.3-0.1V5.8l-0.4,0.1c-3.6,0.6-7.2,1.7-10.6,3.1L31,9v22.8l0.2,0.1c1.1,0.5,2.3,0.9,3.5,1.3
				l0.5,0.2V11.9C37.5,11.1,39.7,10.5,42,10.1"
					/>
					<path
						class="st1"
						d="M45.4,5.3H45c0,0,0,16,0,16.8c-1.6-0.2-2.8-1.5-2.8-3.1v-6.1l-0.4,0.1c-1.2,0.2-2.4,0.5-3.5,0.9L38,13.9V19
				c0,3.9,3.1,7.1,7,7.3c0,0.4,0,1.2,0,1.2c0,1.7-1.4,3.1-3.1,3.1c-0.2,0-0.4,0-0.7-0.1c0,0-1.7-0.4-2.7-0.6L38,29.8v4.3l0.2,0.1
				c0.5,0.1,1.7,0.4,2.1,0.5c0.5,0.1,1,0.2,1.5,0.2c4.1,0,7.3-3.3,7.3-7.3V5.1h-0.4C47.6,5.1,46.5,5.2,45.4,5.3"
					/>
					<path
						class="st1"
						d="M70,9c-2.8-1.6-6.3-1.2-8.6,0.9c-2-3-5.4-4.9-9-4.9H52v30.6h0.4c1.2,0,2.4-0.1,3.5-0.2h0.3V10.5
				c1.8,1.2,2.8,3.3,2.8,5.4V35l0.4-0.1c1.2-0.2,2.4-0.5,3.5-0.8l0.3-0.1V15.4c0-1.6,1.2-2.9,2.8-3.1c0,0.8,0,21.1,0,21.1l0.5-0.2
				c1.2-0.4,2.4-0.8,3.5-1.3l0.2-0.1V9.1L70,9"
					/>
				</g>
			</g>
		</g>
	</svg>

	<svg
		v-else
		:style="cssProps()"
		class="image-logo-2"
		width="83"
		version="1.1"
		id="Слой_1"
		x="0px"
		y="0px"
		viewBox="0 0 608.00001 478.99999"
		xml:space="preserve"
		sodipodi:docname="logo_2(1).svg"
		inkscape:version="1.1 (c68e22c387, 2021-05-23)"
		xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
		xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:svg="http://www.w3.org/2000/svg"
	>
		<defs id="defs62" />

		<g id="Слой_x0020_1" transform="translate(5.9911976,-181.5088)">
			<g id="Слой_2_00000049212474003842003630000000336559448320772524_">
				<g id="Layer_2">
					<path
						class="st0"
						d="m 21,582.3 c 0,0 99.6,51.1 263.3,51.1 163.7,0 289.9,-51 289.9,-51 V 279.8 c 0,0 -96,-71.3 -276.6,-71.3 C 117,208.5 21,266.9 21,266.9 Z"
						id="path4"
					/>
					<g id="g34">
						<g id="g24">
							<path
								class="st1"
								d="M 183.7,226.8 V 282 l -7.8,1.5 c -27.8,5.2 -55.4,12.6 -82.1,21.9 0,13.1 0,279.1 0,279.1 L 81.2,580.3 C 68.2,576 52,570.1 39.1,565 v 7.5 l 6,2.5 c 14.9,6.2 33.6,13 48.8,18.1 l 12.6,4.2 c 0,0 0,-267.9 0,-281.1 26.8,-9.2 54.3,-16.6 82.2,-21.9 l 7.9,-1.7 v -67.5 z"
								id="path6"
							/>
							<g id="g14">
								<path
									class="st1"
									d="m 208.9,508 c 0.3,-1.8 0.5,-3.7 0.5,-5.6 v -10.2 c -24.8,-2.4 -47.8,-14.1 -64.5,-32.7 13.6,24.4 36.8,42 64,48.5 z"
									id="path8"
								/>
								<path
									class="st1"
									d="m 276.5,218.6 h -2.9 v 283.8 c -0.1,54.9 -44.6,99.5 -99.5,99.5 0,0 -0.1,0 -0.1,0 -6.8,0 -13.6,-0.7 -20.2,-2.1 -3.7,-0.8 -17.2,-3.5 -24.6,-5.3 v 9.4 l 10.2,2.5 c 6,1.7 22.3,5.5 27.2,6.2 67.8,10.2 119.9,-44.2 119.9,-106.3 v -288 z"
									id="path10"
								/>
								<path class="st1" d="m 183.7,393.2 c -3.1,-5.3 -4.8,-11.4 -4.8,-17.5 v 0 -59.9 l 6.2,2.2 -1.3,0.3 z" id="path12" />
							</g>
							<g id="g22">
								<path
									class="st1"
									d="m 551.2,277.8 c -2.6,-1.5 -5.3,-2.9 -8,-4.2 v 287.3 l -5.9,2.5 c -14.9,6.2 -32.1,11.9 -47.4,16.8 v 14.6 l 14.6,-4.2 c 15.4,-5.1 30.7,-10.9 45.6,-17.1 L 556,571 V 280.5 Z"
									id="path16"
								/>
								<path
									class="st1"
									d="m 363.4,295.1 c 0,34 0,313.9 0,313.9 l -8.6,0.9 c -14.1,1.5 -31.7,2.5 -45.9,3 v 10.7 l 13.2,-0.3 c 15.2,-0.5 30.6,-1.6 45.6,-3.1 l 8.6,-0.9 c 0,0 0,-265.5 0,-308.8 -3.6,-5.7 -8,-10.8 -12.9,-15.4 z"
									id="path18"
								/>
								<path
									class="st1"
									d="m 479,312.9 c -15.1,4.2 -25.6,17.9 -25.7,33.6 v 245.2 l -7.3,1.8 c -14.5,3.7 -32.6,6.9 -47.3,9.5 v 12.6 l 14.5,-2 c 15.3,-2.7 30.6,-6 45.6,-9.8 l 7.3,-1.9 v -245.6 0 c 0,-10.5 4.7,-20.4 12.8,-27 z"
									id="path20"
								/>
							</g>
						</g>
						<g id="g32">
							<path
								class="st1"
								d="m 84.1,571.3 c -15.3,-5 -30.2,-10.7 -45,-16.8 V 267.8 c 43.3,-18 88.8,-31.4 134.9,-39.5 V 274 c -30.5,5.8 -60.7,14.1 -89.9,24.6 z"
								id="path26"
							/>
							<path
								class="st1"
								d="m 263.9,218.9 c -15.1,0.5 -30.1,1.6 -45,3.1 v 216.1 c -24.8,0 -44.9,-20.2 -44.9,-45 v 0 -73.4 c -15.1,3.1 -30.1,6.9 -45,11.3 v 62.1 0 c 0,49.6 40.3,89.9 89.9,89.9 v 0 19.3 c 0,24.8 -20.2,45 -45,45 -3.1,0 -6.3,-0.3 -9.4,-1 -3.1,-0.7 -21.7,-4.6 -35.5,-8.4 v 46.3 c 6,1.7 24.3,5.6 26.5,6.1 6,1.2 12.1,1.9 18.2,1.9 0,0 0.1,0 0.1,0 0,0 0.1,0 0.1,0 49.6,0 89.8,-40.2 89.9,-89.7 V 218.9 Z"
								id="path28"
							/>
							<path
								class="st1"
								d="m 488.7,256.7 c -0.1,0 -0.1,0 0,0 -24.3,0 -47.5,9.8 -64.4,27.3 C 400,243.6 356.2,218.9 309,218.9 c 0,0 -0.1,0 -0.1,0 v 384.5 c 15.1,-0.5 30.1,-1.5 45,-3.1 V 275.9 c 27.8,16 44.9,45.7 44.9,77.8 0,0 0,0 0,0.1 V 594 c 15.2,-2.8 30.2,-6 45,-9.6 V 346.6 c 0,-24.8 20.2,-44.9 45,-44.9 v 0 269.6 c 15.3,-5 30.2,-10.7 45,-16.8 V 268.8 c -13.6,-7.9 -29.1,-12 -44.8,-12 -0.2,-0.1 -0.3,-0.1 -0.3,-0.1 z"
								id="path30"
							/>
						</g>
					</g>
				</g>
			</g>
			<path
				class="st2"
				d="M 84.1,571.3 V 298.6 C 113.3,288.1 143.5,279.8 174,274 v -45.7 c -46.1,8.1 -91.6,21.5 -134.9,39.5 v 286.6 c 14.8,6.2 29.8,11.8 45,16.9 z"
				id="path38"
			/>
			<path
				class="st2"
				d="M 93.8,305.3 V 584.4 L 81.2,580.2 C 68.2,576 52,570.1 39.1,565 v 7.5 l 6,2.5 c 14.9,6.2 33.6,13 48.8,18.1 l 12.6,4.2 V 316.2 c 26.8,-9.2 54.3,-16.6 82.2,-21.9 l 7.9,-1.7 v -67.5 l -12.8,1.6 V 282 l -7.8,1.5 c -27.9,5.2 -55.5,12.6 -82.2,21.8 z"
				id="path40"
			/>
			<path
				class="st2"
				d="m 129.1,393.2 c 0,49.6 40.3,89.9 89.9,89.9 v 0 19.3 c 0,24.8 -20.2,45 -45,45 -3.1,0 -6.3,-0.3 -9.4,-1 -3.1,-0.7 -21.7,-4.6 -35.5,-8.4 v 46.3 c 6,1.7 24.3,5.6 26.5,6.1 6,1.2 12.1,1.9 18.2,1.9 h 0.1 0.1 c 49.6,0 89.8,-40.2 89.9,-89.7 V 218.9 c -15.1,0.5 -30.1,1.6 -45,3.1 v 216.1 c -24.8,0 -44.9,-20.2 -44.9,-45 v -73.4 c -15.1,3.1 -30.1,6.9 -45,11.3 v 62.2 z"
				id="path42"
			/>
			<path
				class="st2"
				d="m 209.4,492.2 c -24.8,-2.4 -47.8,-14.1 -64.5,-32.7 13.6,24.3 36.9,41.9 64,48.4 0.3,-1.8 0.5,-3.7 0.5,-5.6 z"
				id="path44"
			/>
			<path class="st2" d="m 178.9,375.6 c 0,6.2 1.6,12.2 4.8,17.5 l 0.1,-74.9 1.3,-0.3 -6.2,-2.2 z" id="path46" />
			<path
				class="st2"
				d="m 273.6,218.6 v 283.8 c -0.1,54.9 -44.6,99.5 -99.5,99.5 H 174 c -6.8,0 -13.6,-0.7 -20.2,-2.1 -3.7,-0.8 -17.2,-3.5 -24.6,-5.3 v 9.4 l 10.2,2.5 c 6,1.7 22.3,5.5 27.2,6.2 67.8,10.2 119.9,-44.2 119.9,-106.3 v -288 l -10,0.3 z"
				id="path48"
			/>
			<path
				class="st2"
				d="M 488.7,256.7 Z M 308.9,218.9 v 384.5 c 15.1,-0.5 30.1,-1.5 45,-3.1 V 275.9 c 27.8,16 44.9,45.7 44.9,77.8 v 0.1 240.2 c 15.2,-2.8 30.2,-6 45,-9.6 V 346.6 c 0,-24.8 20.2,-44.9 45,-44.9 v 269.6 c 15.3,-5 30.2,-10.7 45,-16.8 V 268.8 c -13.6,-7.9 -29.1,-12 -44.8,-12 h -0.2 -0.1 c -24.2,0 -47.5,9.8 -64.3,27.3 -24.5,-40.5 -68.2,-65.2 -115.5,-65.2 z"
				id="path50"
			/>
			<path
				class="st2"
				d="M 363.4,295.1 V 609 l -8.6,0.9 c -14.1,1.5 -31.7,2.5 -45.9,3 v 10.7 l 13.2,-0.3 c 15.2,-0.5 30.6,-1.6 45.6,-3.1 l 8.6,-0.9 V 310.5 c -3.6,-5.7 -8,-10.8 -12.9,-15.4 z"
				id="path52"
			/>
			<path
				class="st2"
				d="m 453.3,346.5 v 245.2 l -7.3,1.8 c -14.5,3.7 -32.6,6.9 -47.3,9.5 v 12.6 l 14.5,-2 c 15.3,-2.7 30.6,-6 45.6,-9.8 l 7.3,-1.9 v -245.6 0 c 0,-10.5 4.7,-20.4 12.8,-27 l 0.1,-16.4 c -15.1,4.2 -25.6,17.9 -25.7,33.6 z"
				id="path54"
			/>
			<path
				class="st2"
				d="m 543.2,273.6 v 287.3 l -5.9,2.5 c -14.9,6.2 -32.1,11.9 -47.4,16.8 v 14.6 l 14.6,-4.2 c 15.4,-5.1 30.7,-10.9 45.6,-17.1 L 556,571 V 280.5 l -4.8,-2.8 c -2.6,-1.4 -5.2,-2.8 -8,-4.1 z"
				id="path56"
			/>
		</g>
	</svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'ImageLogo',

	props: ['small'],
	data: () => ({}),
	methods: {
		cssProps(): any {
			return {
				'--primary-color': this.$vuetify.theme.themes.light.accent,
			};
		},
	},
});
</script>

<style lang="less" scoped>
.image-logo-1,
.image-logo-2 {
	cursor: pointer;
	transition: 0.25s ease;

	&:hover {
		transform: scale(1.05);
	}
}

.image-logo-1 {
	.st0 {
		fill: #ffffff;
		stroke: #ffffff;
		stroke-width: 5;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	.st1 {
		fill: var(--primary-color);
	}
}

.image-logo-2 {
	.st0 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #ffffff;
		stroke: #ffffff;
		stroke-width: 42.0599;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
	}

	.st1 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #ffffff;
		stroke: #ffffff;
		stroke-miterlimit: 10;
	}

	.st2 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: var(--primary-color);
	}
}
</style>
