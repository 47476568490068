<template>
	<div class="section-buttons">
		<v-row>
			<v-col :md="btn.width" :sm="btn.widthSm || null" v-for="(btn, index) in buttons" :key="index">
				<a :href="btn.href">
					<v-img :src="btn.image" />
				</a>
			</v-col>
		</v-row>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionButtons',
	data: () => ({
		buttons: [
			{ image: require('@/assets/images/buttons/2024/001.jpg'), href: '/master-class-figure/', width: 6, widthSm: 12 },
			{ image: require('@/assets/images/buttons/2024/002.jpg'), href: '/master-class-alina/', width: 6, widthSm: 12 },
			{ image: require('@/assets/images/buttons/2024/003.jpg'), href: '/master-class-fhr/', width: 12 },
		],
	}),
});
</script>
