<template>
	<div class="menu-top" :class="Small ? 'small' : null">
		<div class="menu-top__content" :style="styleContent()" ref="menu">
			<div>
				<v-btn icon x-large @click="draw">
					<v-icon x-large color="white">mdi-menu</v-icon>
				</v-btn>
			</div>
			<div class="menu-top__logo" v-if="!$vuetify.breakpoint.xs">
				<a href="/"><image-logo :small="Small" /></a>
			</div>
			<div class="text-right white--text menu-top__info">
				<p class="mb-0">
					<!-- <template v-if="!notime"> Ежедневно {{ Workhours }} <br /> </template> -->
					<a class="white--text" style="text-decoration: none" :href="'tel: ' + Phone">{{ Phone }}</a><br />
					<span>Кол-центр работает с 10:00 до 22:00</span><br />
					<a class="white--text" style="text-decoration: none" :href="`mailto: ${Mail}`">{{ Mail }}</a><br /> 
				</p>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { CONTACT_MAIL, CONTACT_PHONE, WORKHOURS } from '@/main';
import Vue from 'vue';
import ImageLogo from './Generic/ImageLogo.vue';
import { EventBus } from '@/codebase/EventBus';

export default Vue.extend({
	components: { ImageLogo },
	name: 'MenuTop',

	props: ['notime'],

	data: () => ({
		ScrollTop: 0 as number,
		Small: false as boolean,
		Height: 0 as number,
		Workhours: WORKHOURS,
		Phone: CONTACT_PHONE,
		Mail: CONTACT_MAIL,
	}),

	created(): void {
		document.addEventListener('scroll', this.scroll);
	},
	mounted(): void {
		this.Height = (this.$refs.menu as HTMLElement).offsetHeight;
	},
	destroyed(): void {
		document.removeEventListener('scroll', this.scroll);
	},

	methods: {
		scroll() {
			this.ScrollTop = window.pageYOffset;
			this.Height = (this.$refs.menu as HTMLElement).offsetHeight;
			this.Small = this.ScrollTop > this.Height;
		},
		draw() {
			EventBus.$emit('draw');
		},
		styleContent(): any {
			return {
				backgroundColor: this.$vuetify.theme.themes.light.accent,
			};
		},
	},
});
</script>

<style scoped lang="less">
.menu-top {
	position: sticky;
	top: 0;
	z-index: 2;
	max-width: 1400px;
	margin-inline: auto;

	.menu-top__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-inline: 1rem;
		overflow: visible;
		max-height: 160px;

		& > div {
			flex: 1;
		}

		.menu-top__logo {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			top: 8px;
		}
	}
}

@media (max-width: 599px) {
	.menu-top {
		.menu-top__content {
			.menu-top__info {
				flex: 3;

				p {
					font-size: 12px;
				}
			}
		}
	}
}
</style>
