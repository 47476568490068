export default class PriceDate {
	static Now: Date = new Date();
	private readonly Months: Array<string> = [
		'января',
		'февраля',
		'марта',
		'апреля',
		'мая',
		'июня',
		'июля',
		'августа',
		'сентября',
		'октября',
		'ноября',
		'декабря',
	];

	Start!: Date;
	End!: Date;
	Data?: TableData;

	constructor(start: string, end: string) {
		this.Start = new Date(start);
		this.End = new Date(end);
	}

	Format(end?: boolean): string {
		const date = end ? this.End : this.Start;
		const d = date.getDate(),
			m = date.getMonth(),
			y = date.getFullYear();
		const string = `${d} ${this.Months[m]} ${y}`;
		return string;
	}

	get Visible(): boolean {
		return PriceDate.Now.getTime() < this.End.getTime();
	}

	get Active(): boolean {
		return PriceDate.Now.getTime() > this.Start.getTime() && PriceDate.Now.getTime() < this.End.getTime();
	}
}

class TableData {
	items?: Array<TableDataService>;
	timetable?: Array<string>;
}

class TableDataService {
	prices?: Array<number | null> | number | string;
	title!: string;
	type?: number;
}
