<template>
	<v-app>
		<nav-drawer />
		<v-main>
			<dialog-disabled />
			<menu-top />
			<div class="gum-container">
				<v-img :src="Banner001" min-height="100" max-height="200" />
				<section-info />
				<section-buttons class="my-6" />
				<section-schedule class="mb-15" />
				<a href="https://ingos.ru/gum-katok/lifeline/calc" target="_blank">
					<v-img class="my-6" :src="Banner003" />
				</a>
				<!--<a href="https://gum-katok.pulse.insure/" target="_blank">
					<v-img class="my-6" :src="Banner004" />
				</a>
				<a href="https://nloto.ru/lottery/mechtallion?utm_source=gum_ice&utm_medium=special&utm_campaign=mechtallion" target="_blank">
					<v-img class="my-6" :src="Banner005" />
				</a>-->
				<yandex-map />
				<section-map class="my-6" />
				<section-gallery class="mb-12" />
				<section-partners class="mb-12" />
				<section-infopartners class="mb-15" />
				<the-footer />
			</div>
		</v-main>
	</v-app>
</template>

<script lang="ts">
import './style.less';

import Vue from 'vue';
import MenuTop from './components/MenuTop.vue';
import SectionButtons from './components/SectionButtons.vue';
import SectionGallery from './components/SectionGallery.vue';
import SectionInfo from './components/SectionInfo.vue';
import SectionInfopartners from './components/SectionInfopartners.vue';
import SectionMap from './components/SectionMap.vue';
import SectionPartners from './components/SectionPartners.vue';
import SectionSchedule from './components/SectionSchedule.vue';
import YandexMap from './components/YandexMap.vue';
import NavDrawer from './components/NavDrawer.vue';
import DialogDisabled from './components/Generic/DialogDisabled.vue';
import TheFooter from './components/Generic/TheFooter.vue';

export default Vue.extend({
	components: {
		MenuTop,
		SectionInfo,
		YandexMap,
		SectionButtons,
		SectionMap,
		SectionInfopartners,
		SectionGallery,
		SectionPartners,
		SectionSchedule,
		NavDrawer,
		DialogDisabled,
		TheFooter,
	},
	name: 'App',

	data: () => ({
		Banner001: require('./assets/images/banners/2024/001.png'),
		Banner003: require('./assets/images/banners/2024/003.png'),
		Banner004: require('./assets/images/banners/2024/004.png'),
		Banner005: require('./assets/images/banners/2024/005.jpg'),
	}),
});
</script>

<style scoped lang="less">
.gum-container {
	max-width: 1400px;
	margin-inline: auto;
	overflow: hidden;
}
</style>
