import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#7a1319',
				secondary: '#8B1C11',
				accent: '#ddb46b',
				error: '#b71c1c',
			},
			dark: {
				primary: '#FFFFFF',
			},
		},
	},
});
