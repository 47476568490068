<template>
	<div class="section-infopartners">
		<p class="text-h5 mb-6 font-weight-bold text-center primary--text">Мы благодарим наших партнеров</p>
		<v-row
			class="section-infopartners__logos align-center mx-auto d-flex justify-center">
			<div v-for="Item in ItemsRowOne" class="mx-9" :key="Item.id">
				<a :href="Item.url">
					<v-img :src="Item.image" contain :width="Item.width" height="100" class="mx-auto" />
				</a>
			</div>
		</v-row>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionPartners',
	data: () => ({
		ItemsRowOne: [
			{
				id: 0,
				image: require('@/assets/images/partners/2024/kuper.svg'),
				url: 'https://kuper.ru/',
				width: 247,
			},
			{
				id: 1,
				image: require('@/assets/images/partners/2024/ingostrah.svg'),
				url: 'https://www.ingos.ru/',
				width: 194,
			},
			{
				id: 2,
				image: require('@/assets/images/partners/2024/pochta.svg'),
				url: 'https://www.pochta.ru/',
				width: 197, 
			},
			{
				id: 3,
				image: require('@/assets/images/partners/2024/cool_cola.svg'),
				url: 'https://ochakovo.ru/brand/coolcola/',
				width: 210,
			},
			{
				id: 4,
				image: require('@/assets/images/partners/2024/petelinka.svg'),
				url: 'https://petelinka.ru/',
				width: 187,
			},
			{
				id: 6,
				image: require('@/assets/images/partners/2024/exeed.svg'),
				url: 'https://exeed.ru/',
				width: 215,
			},
			{
				id: 7,
				image: require('@/assets/images/partners/2024/psb.svg'),
				url: 'https://www.psbank.ru/',
				width: 197,
			},
			{
				id: 8,
				image: require('@/assets/images/partners/2024/Logo_Partner_12.svg'),
				url: 'https://nloto.ru/?utm_source=gum&utm_medium=qr&utm_campaign=NY_mechtallion&utm_content=',
				width: 197,
			},
			{
				id: 9,
				image: require('@/assets/images/partners/2024/bosco.svg'),
				url: 'https://boscobrand.ru/',
				width: 245,
			}
		] as Array<partnerLink>,
		ItemsRowTwo: [			
		] as Array<partnerLink>,
	}),
});

class partnerLink {
	id!: number;
	image!: string;
	url!: string;
	width!: number;
}
</script>

<style lang="scss">
.section-infopartners__logos {
	max-width: 1400px;

	.section-infopartners__logo {
		width: 100%;
		@media (min-width: 442px) {
			&:last-of-type {
				margin-bottom: 2rem;
			}
		}
	}
}
</style>
