<template>
	<div class="the-footer d-flex align-center px-6 white--text justify-space-between" :style="footerStyle()">
		<span>{{ Phone }}</span>
		<img :src="logo" height="50" />
		<a class="white--text" :href="`mailto: ${Email}`">{{ Email }}</a>

		<!-- <div v-if="!$vuetify.breakpoint.xs"></div>
		<div v-if="!$vuetify.breakpoint.xs">
			<image-logo small="true" />
		</div>
		<div class="pa-2 white--text text-right">
			<p class="mb-0">
				Ежедневно {{ Workhours }} <br />
				<a class="white--text" style="text-decoration: none" :href="'tel: ' + Phone">{{ Phone }}</a>
				<span class="mx-2">|</span>
				<a class="white--text" :href="`mailto: ${Email}`">{{ Email }}</a>
			</p>
		</div> -->
	</div>
</template>

<script lang="ts">
import { CONTACT_MAIL, CONTACT_PHONE, WORKHOURS } from '@/main';
import Vue from 'vue';

import ImageLogo from './ImageLogo.vue';
export default Vue.extend({
	components: {
		//ImageLogo,
	},
	name: 'TheFooter',
	data: () => ({
		Workhours: WORKHOURS,
		Phone: CONTACT_PHONE,
		Email: CONTACT_MAIL,
		logo: require('@/assets/images/2024/logo_footer.svg'),
	}),
	methods: {
		footerStyle(): any {
			return { backgroundColor: this.primaryColor() };
		},
		primaryColor() {
			return this.$vuetify.theme.currentTheme.accent;
		},
	},
});
</script>

<style lang="scss" scoped>
.the-footer {
	padding-block: 1rem;
	margin-top: 120px;
	img {
		max-width: 200px;
	}

	@media (max-width: 550px) {
		font-size: 12px;
		img {
			max-width: 64px;
		}
	}
}
</style>
