<template>
	<div class="section-infopartners mt-10 pt-10">
		<p class="text-h5 my-5 text-center primary--text font-weight-bold">Фотогалерея</p>

		<v-slide-group show-arrows>
			<v-slide-item v-for="(Item, index) in Previews" :key="index">
				<v-dialog v-model="Active[index]" scrollable>
					<template v-slot:activator="{ on, attrs }">
						<v-img v-on="on" v-bind="attrs" :src="Item" max-width="256" class="mx-1" />
					</template>
					<v-card style="overflow: hidden">
						<v-img :src="Items[index]" height="100%" contain />
						<v-btn @click="$set(Active, index, false)" color="primary" small absolute top right fab class="mt-8">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card>
				</v-dialog>
			</v-slide-item>
		</v-slide-group>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionGallery',
	data: () => ({
		Active: [] as Array<boolean>,
		Items: [
			require('./../assets/images/gallery/2024/main/Gum_Katok_01.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_02.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_04.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_05.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_06.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_07.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_08.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_09.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_10.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_11.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_12.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_13.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_14.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_15.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_16.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_17.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_18.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_19.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_20.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_21.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_22.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_23.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_24.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_25.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_26.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_27.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_28.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_29.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_30.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_31.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_32.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_33.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_34.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_35.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_36.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_37.jpg'),
			require('./../assets/images/gallery/2024/main/Gum_Katok_38.jpg'),
		],
		Previews: [
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_01.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_02.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_04.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_05.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_06.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_07.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_08.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_09.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_10.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_11.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_12.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_13.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_14.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_15.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_16.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_17.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_18.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_19.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_20.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_21.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_22.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_23.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_24.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_25.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_26.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_27.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_28.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_29.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_30.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_31.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_32.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_33.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_34.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_35.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_36.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_37.jpg'),
			require('./../assets/images/gallery/2024/main/1x1/Gum_Katok_small_38.jpg'),
		],
	}),
});
</script>
