<template>
	<v-navigation-drawer v-model="Enabled" temporary app width="422">
		<div class="d-flex align-center justify-space-between">
			<v-btn class="ma-3" icon x-large @click="Enabled = !Enabled">
				<v-icon color="primary" x-large>mdi-close</v-icon>
			</v-btn>
			<v-spacer />
			<div class="pr-3">
				<a v-for="(Item, index) in SocialLinks" :key="index" :href="Item.link" target="_blank">
					<v-btn icon class="mx-1">
						<the-icon :type="Item.icon" />
					</v-btn>
				</a>
			</div>
		</div>

		<v-list dense>
			<v-list-item-group>
				<a v-for="(Item, index) in DrawerLinks" :href="Item.link" v-bind:key="index">
					<v-list-item>
						{{ Item.text }}
					</v-list-item>
				</a>
			</v-list-item-group>
		</v-list>

		<v-alert type="info" color="primary" prominent class="text-center mb-0 rounded-0">
			<span
				>ГУМ-каток работает ежедневно <br />
				{{ Workhours }}</span
			>
			<hr color="white" style="border-bottom: 0; margin-block: 5px 4px" />
			<span
				>Единая справочная: <br />
				{{ Phone }} | <a class="white--text" href="mailto: Email">{{ Email }}</a></span
			>
		</v-alert>
	</v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';

import { EventBus } from '@/codebase/EventBus';
import { CONTACT_MAIL, CONTACT_PHONE, WORKHOURS } from '@/main';
import TheIcon from './Generic/TheIcon.vue';

export default Vue.extend({
	components: { TheIcon },
	name: 'NavDrawer',
	data: () => ({
		Enabled: false,
		Workhours: WORKHOURS,
		Email: CONTACT_MAIL,
		Phone: CONTACT_PHONE,
		DrawerLinks: [
			{ text: 'Новости', link: 'https://gum.ru/news/' },
			{ text: 'История', link: 'https://gum.ru/history/' },
			{ text: 'Магазины', link: 'https://gum.ru/shops/' },
			{ text: 'ГУМ-Каток', link: 'http://gum-katok.ru/?lang=ru' },
			{ text: 'ГУМ-Ярмарка', link: 'http://gum-katok.ru/yarmarka-new/' },
			{ text: 'Гастроном №1', link: 'https://gum.ru/gastronom-1/' },
			{ text: 'Кафе и рестораны', link: 'https://gum.ru/cafe/' },
			//{ text: "Витрины ГУМа", link: "https://gum.ru/shopwindows/" },
			{ text: 'Кинозал ГУМа', link: 'https://gum.ru/kinozal/' },
			{ text: 'Демонстрационный Зал', link: 'https://gum.ru/demzal.html' },
			{ text: 'Система привилегий', link: 'https://gum.ru/shop/magic-cards.html' },
			{ text: 'Контакты', link: 'https://gum.ru/contacts/' },
		] as Array<NavigationLink>,
		SocialLinks: [
			{ icon: 'tg', link: 'https://t.me/GUMRedSquare' },
			{ icon: 'vk', link: 'https://vk.com/gum_russia' },
			// { icon: "mdi-facebook", link: "https://www.facebook.com/gum.russia" },
			// { icon: "mdi-vk", link: "http://www.vk.com/gum_russia" },
			// { icon: "mdi-twitter", link: "https://twitter.com/gum_news_ru" },
			// { icon: "mdi-instagram", link: "https://www.instagram.com/gum_ru/" },
		] as Array<NavigationLink>,
	}),
	created(): void {
		EventBus.$on('draw', () => {
			this.Enabled = !this.Enabled;
		});
	},
});

class NavigationLink {
	text?: string;
	link?: string;
	icon?: string;
}
</script>
