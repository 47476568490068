<template>
	<v-btn width="100%" max-width="600" @click="modelDialog = !modelDialog" class="rounded-0 mb-3 mx-auto" color="primary">
		Прокат оборудования
		<!-- Dialog: ПРАВИЛА ПРОКАТА КОНЬКОВ -->
		<v-dialog v-model="modelDialog" max-width="700" scrollable>
			<v-card>
				<v-card-title>
					<b>ПРАВИЛА ПРОКАТА КОНЬКОВ:</b>
					<v-spacer />
					<v-btn @click="modelDialog = false" fab small color="primary">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<p class="pt-4">
						Выдача коньков и инвентаря производится посетителям только при наличии билета на текущий сеанс катания, 
						билета на прокат инвентаря и осуществляется под залог в пункте проката коньков.
					</p>
					<p>В залог принимаются:</p>
					<ul class="mb-5">
						<li>денежный залог в размере 2 000 (две тысячи) рублей наличными;</li>
						<li>водительское удостоверение или свидетельство о регистрации ТС;</li>
						<li>удостоверение военнослужащего;</li>
						<li>пенсионное удостоверение</li>
					</ul>
					<v-alert type="warning" prominent>
						<b>ВНИМАНИЕ!</b> Паспорт гражданина, военный билет, пенсионная справка, студенческий билет и личные 
						ценные вещи в качестве залога не принимаются!
					</v-alert>
					<p>
						Сдачу документов в залог и получение документов из залога должен осуществлять непосредственно владелец 
						документов. Ветхие документы в залог не принимаются, администрация вправе отказать в обслуживании без объяснения причины.
					</p>
					<p>Посетитель несет полную ответственность за сохранность инвентаря, полученного в прокат.</p>
					<p>
						К возврату принимается только тот инвентарь, который был выдан данному посетителю. При потере коньков взимается полная их 
						стоимость в размере 5 000 рублей. При порче инвентаря взимается залоговая стоимость в соответствии с 
						действующими тарифами (2 000 рублей).
					</p>

					<p>На один вид залога можно взять одну пару коньков.</p>
					<p>Комплект защиты и шлем выдается бесплатно при прокате коньков.</p>
					<p>В случае использования только комплекта защиты или шлема, выдача так же осуществляется под залог.</p>
					<p>За утерю номерного знака проката штраф 500 рублей. При утере номерка сразу сообщить сотруднику проката.</p>
					<p>За вещи, оставленные без присмотра, администрация ответственности не несет.</p>
					<p>
						Посетитель обязан бережно относиться к прокатному инвентарю, соблюдать правила его эксплуатации, 
						не нарушать правил техники безопасности и не использовать его не по назначению
					</p>
					<p>
						Администрация пункта проката вправе отказать посетителю в пользовании услугами пункта проката инвентаря при 
						отсутствии необходимого инвентаря в наличии, при невыполнении условий внесения залога, а также если посетитель, 
						по мнению администрации, находится в состоянии алкогольного или наркотического опьянения, без компенсации стоимости билета.
					</p>

					<p>
						Факт получения посетителем инвентаря означает, что посетитель ознакомлен с данными правилами проката и дальнейшую 
						ответственность, связанную с его использованием, берет на себя.
					</p>

					<p>
						Услуга заточки коньков оказывается для инвентаря, предназначенного для массового потребительского катания. 
						Инвентарь, предназначенный для профессионального катания (хоккейные или фигурные коньки), в работу не принимаются.
					</p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class ButtonRent extends Vue {
	modelDialog = false;
}
</script>
