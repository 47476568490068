<template>
	<v-btn width="100%" max-width="600" @click="modelDialog = !modelDialog" class="rounded-0 mb-3 mx-auto" color="primary">
		Правила катка
		<!-- Dialog: ПРАВИЛА ГУМ-КАТКА НА КРАСНОЙ ПЛОЩАДИ -->
		<v-dialog v-model="modelDialog" max-width="700" scrollable>
			<v-card>
				<v-card-title>
					<b>ПРАВИЛА ГУМ-КАТКА НА КРАСНОЙ ПЛОЩАДИ:</b>
					<v-spacer />
					<v-btn @click="modelDialog = false" fab small color="primary">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<ol class="pt-4">
						<li>
							Организатором и администрацией катка является Акционерное Общество «Торговый Дом ГУМ». 
							Место нахождения организатора: Россия, 109012, г. Москва, Красная площадь, д. 3.
						</li>
						<li>
							Ледовый каток является местом проведения досуга и культурного отдыха посетителей и включает в себя 
							ледовую площадку, раздевалку, пункты проката оборудования, кафе, туалетные комнаты, места для отдыха, 
							а также иные вспомогательные помещения.
						</li>
						<li>
							Ледовый каток работает ежедневно с 8:30 до 23:30 часов, без выходных. График и время работы может 
							быть изменено решением администрации катка.
						</li>
						<li>Время работы катка разделяется на сеансы, каждый из которых длится 1 час.</li>
						<li>
							Сеансы плановой обработки льда на ледовой площадке проводятся через каждый 1 час. Время плановой 
							обработки льда составляет 30 минут. Время обработки льда может быть изменено решением администрации катка. 
							На время обработки льда посетители на ледовую площадку не допускаются. Посетители обязаны покинуть ледовую 
							площадку на время обработки льда.
						</li>
						<li>
							О начале сеанса обработки льда, а также о готовности ледовой площадки к использованию, посетителям сообщается посредством 
							громкоговорящей связи.
						</li>
						<li>
							На территорию катка допускаются посетители, оплатившие вход. При оплате входа посетителям выдается билет на каток. 
							Посетитель обязан сохранять билет на каток до окончания сеанса.
						</li>
						<li>Билет на каток подлежит возврату за 30 минут до начала сеанса, или обмену, но только в случае отмены сеанса.</li>
						<li>
							Билет на каток действителен на вход/выход в течение времени сеанса, указанного на билете, а также времени 
							на переодевание, за 15 минут до начала сеанса, указанного в билет и 15 минут после окончания сеанса.
						</li>
						<li>
							В случае превышения времени нахождения на катке, указанного в п. 9, посетитель обязан произвести доплату в соответствии 
							с действующими тарифами.
						</li>
						<li>
							Билеты подразделяются на билеты для взрослых, билеты для детей от 7 до 12 лет (включительно), билеты льготной 
							категории и билеты сопровождения (без права выхода на лед).
						</li>
						<li>
							Льготной категорий считаются: дети дошкольного возраста до 6 лет включительно, пенсионеры по возрасту 
							(при предъявлении пенсионного удостоверения или соответствующей справки, заменяющей пенсионное удостоверение), 
							участники СВО и члены их семей (при наличии соответствующих документов) 
						</li>
						<li>
							Стоимость билета на каток и дополнительных услуг определяется решением администрации катка и демонстрируется 
							в месте общего доступа посетителей (около кассы). Продажа билетов в кассах катка осуществляется с 8:30 до 22:45. 
							Скидки по картам лояльности ГУМа и Bosco предоставляются только на билеты, дающие право на КАТАНИЕ НА ЛЬДУ и 
							УСЛУГИ ВИП-комнаты. Скидки по картам лояльности ГУМа и Bosco не суммируются. Скидки по картам лояльности ГУМа и 
							Bosco не распространяются  на услуги пункта проката и услуги кафе.
						</li>
						<li>
							В стоимость билета на КАТАНИЕ НА ЛЬДУ не входит стоимость проката коньков, стоимость дополнительных услуг, а 
							также обслуживание в зонах кафе, и оплачивается посетителями отдельно в кассах катка и кафе.
						</li>
						<li>
							Администрация не несет ответственности за поддельные билеты и билеты, приобретенные в неустановленных местах. 
							Утерянные билеты не восстанавливаются.
						</li>
						<li>
							На ледовую площадку допускаются посетители, одевшие коньки. Дети до 7 лет на ледовую площадку допускаются в 
							коньках и только в сопровождении взрослых на коньках (на одного ребенка – один взрослый). Движение посетителей на 
							ледовой площадке осуществляется против часовой стрелки. 
						</li>
						<li>
							В случае отсутствия у посетителей собственных коньков, они могут взять их у организатора катка в пункте проката, 
							находящемся рядом с ледовой площадкой, на прокат.
						</li>
						<li>
							Пункт проката коньков работает в соответствии с графиком работы катка. Выдача коньков прекращается за 30 минут 
							до прекращения работы катка.
						</li>
						<li>
							При необходимости посетители могут сдать свои вещи в гардероб-самообслуживания. Посетителям, сдавшим вещи в 
							гардероб-самообслуживания, выдается ключ-номерок на время сеанса. За утрату ключа-номерка с посетителей взимается 
							штраф в размере 500 рублей. <br />
							Посетитель может воспользоваться гардеробом только во время сеанса, на которое   приобретен билет. В случае 
							оставления вещей в гардеробе после окончания сеанса, в целях обеспечения безопасности, администрация оставляет 
							за собой право вскрытия ячейки гардероба в соответствии с законодательством РФ.
						</li>
						<li>
							Проведение экскурсий, репортажей, конференций, совещаний, профессиональной фотосъемки и киносъемки на территории 
							катка допускается только с согласия администрации катка. 
						</li>
						<li>
							Администрация не несет ответственности за сданные в гардероб-ящики ценные вещи, а также за оставленные без присмотра 
							вещи посетителей.
						</li>
						<li>
							Администрация не несет ответственности за вред, причиненный жизни, здоровью, репутации, имуществу посетителей, 
							в результате действий самих посетителей и /или третьих лиц.
						</li>
						<li>
							Администрация не несет ответственности за вред, полученный посетителями в результате обострения у них хронических 
							заболеваний, травм и т.д.
						</li>
						<li>
							<span class="red--text">Посетителям ледового катка запрещается:</span>
							<ul>
								<li>проносить на территорию катка алкогольсодержащие напитки;</li>
								<li>
									проносить на территорию катка оружие, огнеопасные, взрывчатые, ядовитые, пахучие и радиоактивные вещества, 
									легко воспламеняющиеся вещества, колющие и режущие предметы, чемоданы, портфели, крупногабаритные 
									свертки и сумки, стеклянную посуду и иные предметы, мешающие посетителям и/или администрации катка;
								</li>
								<li>
									проносить и использовать на территории катка пиротехнические и шум издающие изделия, включая 
									музыкальную аппаратуру;
								</li>
								<li>проносить на территорию катка и/или ледовую площадку еду и напитки;</li>
								<li>проносить на территорию катка клюшки и шайбы для хоккея (исключая участие в мастер-классе по хоккею);</li>
								<li>проходить на территорию катка с животными без переноски любого типа;</li>
								<li>
									проходить и находиться на территории катка в нетрезвом виде и/или под воздействием наркотических средств, 
									и/или в виде, оскорбляющем человеческое достоинство и общественную нравственность;
								</li>
								<li>мусорить на территории катка;</li>
								<li>бросать на ледовую площадку какие-либо предметы;</li>
								<li>оставлять без присмотра свои вещи;</li>
								<li>
									курить (в том числе вейп, нагревательные устройства для табака или кальян) на ледовой площадке, 
									а также на территории катка;
								</li>
								<li>оставлять малолетних детей без присмотра;</li>
								<li>брать малолетних детей на руки, находясь на ледовой площадке;</li>
								<li>находиться на ледовой площадке во время обработки льда;</li>
								<li>находиться на ледовой площадке в нетрезвом виде;</li>
								<li>находиться на ледовой площадке без коньков;</li>
								<li>находиться на ледовой площадке в конькобежных коньках;</li>
								<li>проносить и/или употреблять на ледовой площадке еду и напитки;</li>
								<li>приносить на лед цветы без запечатанной упаковки;</li>
								<li>совершать какие-либо действия, мешающие другим посетителям катка;</li>
								<li>умышленно наносить какие-либо повреждения ледовому покрытию;</li>
								<li>передвигаться по ледовой площадке, создавая помехи движению других посетителей катка;</li>
								<li>играть на ледовой площадке и территории катка в любые подвижные игры, включая салочки и хоккей;</li>
								<li>осуществлять прыжки, сальто и иные спортивные движения, которые мешают или могут помешать отдыху посетителей;</li>
								<li>выходить на ледовую площадку в нижнем белье или купальных костюмах без верхней одежды;</li>
								<li>
									носить или выставлять напоказ знаки или иную символику, направленную на разжигание расовой, социальной,
									национально и религиозной розни;
								</li>
								<li>
									осуществлять торговлю, наносить надписи и распространять объявления, плакаты и другую продукцию информационного
									содержания;
								</li>
								<li>
									появляться без разрешения администрации катка на ледовой площадке вне сеанса, в служебных и технических
									помещениях;
								</li>
								<li>
									создавать помехи передвижению посетителей и/или персоналу катка, забираться на ограждения, парапеты, трибуны, 
									осветительные устройства, площадки для телевизионных съемок, деревья, мачты, крыши, несущие конструкции, 
									повреждать оборудование и элементы оформления сооружений, и иной инвентарь;
								</li>
								<li>
									допускать выкрики или иные действия, унижающие человеческое достоинство посетителей и оскорбляющие 
									человеческую нравственность.
								</li>
							</ul>
						</li>
						<li>
							Посетители ледового катка обязаны:
							<ul>
								<li>соблюдать настоящие правила;</li>
								<li>бережно относиться к имуществу, находящемуся на территории катка;</li>
								<li>не нарушать общественный порядок и общепринятые правила поведения;</li>
								<li>
									незамедлительно сообщать администрации катка и в правоохранительные органы о случаях обнаружения подозрительных 
									предметов, вещей, захвата людей в заложники и о всех случаях возникновения задымления или пожара;
								</li>
								<li>
									сохранять в течение всего времени нахождения на территории катка документы, дающие право на вход на территорию
									катка;
								</li>
								<li>
									предъявлять представителям администрации катка и сотрудникам правоохранительных органов документы, дающие право на
									вход на территорию катка;
								</li>
								<li>
									соблюдать и поддерживать общественный порядок и общепринятые нормы поведения, вести себя уважительно по отношению 
									к другим посетителям и администрации катка, обслуживающему персоналу, лицам, ответственным за соблюдение порядка 
									на территории катка, не допускать действий, создающих опасность для окружающих.
								</li>
							</ul>
						</li>
						<li>
							Администрация оставляет за собой право потребовать прекращения посетителем пребывания на территории катка, в том 
							числе по причине несоблюдения настоящих правил без компенсации стоимости билета.
						</li>
						<li>
							Для предотвращения травмоопасных ситуаций, Администрация рекомендует посетителям ледового катка воздержаться от 
							использования мобильных средств связи, а также фото и видео аппаратуры, в период нахождения на ледовой площадке.
						</li>
						<li>В целях безопасности, администрация катка может вести постоянную видеозапись на всей территории ледового катка.</li>
						<li>Нахождение посетителя на территории катка подтверждает его согласие с настоящими правилами.</li>
					</ol>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class ButtonRules extends Vue {
	modelDialog = false;
}
</script>
