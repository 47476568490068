<template>
	<svg v-if="type == 'tg'" width="24" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
		<path
			:fill="primary()"
			d="M172.208 30.975L24.4583 87.95C14.375 92 14.4333 97.625 22.6083 100.133L60.5416 111.967L148.308 56.5917C152.458 54.0667 156.25 55.425 153.133 58.1917L82.025 122.367H82.0083L82.025 122.375L79.4083 161.475C83.2416 161.475 84.9333 159.717 87.0833 157.642L105.508 139.725L143.833 168.033C150.9 171.925 155.975 169.925 157.733 161.492L182.892 42.925C185.467 32.6 178.95 27.925 172.208 30.975V30.975Z"
		></path>
	</svg>

	<svg v-else-if="type == 'vk'" width="24" viewBox="0 0 176 101" xmlns="http://www.w3.org/2000/svg">
		<path
			:fill="primary()"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M142.807 101H142.035C132.772 99.458 127.368 93.2901 121.965 87.1221C118.877 84.0382 114.246 76.3282 108.842 77.0992C101.123 78.6412 104.982 90.9771 101.895 97.145C99.5789 101.771 88.7719 101 81.0526 100.229C57.1228 97.916 44.7719 84.8091 34.7368 71.7023C23.1579 57.0534 14.6667 41.6336 6.17544 24.6717C3.85965 19.2748 0 13.1069 0 6.93892C3.85965 3.85495 10.0351 4.62594 16.2105 4.62594C21.614 4.62594 30.1053 3.08396 33.9649 5.39693C36.2807 6.93892 38.5965 13.1069 40.9123 16.9618C45.5439 26.2137 49.4035 33.1527 54.807 40.0916C57.1228 43.1756 60.2105 49.3435 64.8421 48.5725C67.9298 48.5725 68.7017 38.5496 68.7017 32.3817C68.7017 25.4427 68.7018 16.1908 66.386 12.3359C64.8421 8.4809 60.9825 8.4809 57.8947 6.93892C60.9825 0.77098 68.7018 -1.22765e-05 78.7368 -1.22765e-05C87.2281 -1.22765e-05 98.807 -0.771004 101.895 4.62594C104.982 10.0229 102.667 20.0458 102.667 27.7557C102.667 36.2366 101.123 44.7175 106.526 48.5725C111.158 47.8015 113.474 43.9465 115.789 40.0916C121.193 32.3817 125.053 26.2137 129.684 16.9618C131.228 13.1069 133.544 6.16793 136.632 5.39693C141.263 3.85495 149.754 4.62594 156.702 4.62594C162.877 4.62594 173.684 3.08396 175.228 6.93892C176.772 10.0229 172.14 19.2748 170.596 21.5878C165.965 30.8397 160.561 36.2366 154.386 43.9465C152.07 47.0305 147.439 52.4275 147.439 57.0534C147.439 60.9084 151.298 63.9924 154.386 66.3053C160.561 72.4733 165.193 76.3282 170.596 83.2672C172.912 85.5801 176.772 90.9771 176 94.061C174.456 104.855 151.298 98.687 142.807 101Z"
		></path>
	</svg>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
	name: 'TheIcon',
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	methods: {
		primary() {
			return this.$vuetify.theme.currentTheme.primary?.toString();
		},
	},
});
</script>
