<template>
	<v-row class="my-0">
		<v-col md="9" sm="12" class="pr-0 py-0">
			<v-img :src="image" class="fill-height" />
		</v-col>
		<v-col md="3" sm="12" class="pl-0 py-0 pb-md-0 text-subtitle-2">
			<v-sheet color="accent" class="d-flex flex-column justify-center fill-height pa-6 ml-3 ml-sm-0 text-center primary--text">
				<span>
					Каток <br />
					на Красной площади работает <br />
					{{ workingSeason }}.
					<!-- {{ WorkHours }} -->
				</span>
				<a @click.prevent="ScrollTo('#yandex-map')" class="white--primary mb-4" href="#yandex-map">посмотреть на карте</a>
				<span class="mb-2">Билеты можно приобрести онлайн и кассах катка. Дополнительный прокат коньков можно оплатить в кассе катка.</span>
				<br />
				<div class="mt-3">
					<ButtonFAQ />
				</div>
			</v-sheet>
		</v-col>
	</v-row>
</template>

<script lang="ts">
import { DATE_SALE_END, DATE_SALE_START, WORKHOURS } from '@/main';
import Vue from 'vue';

import ButtonFAQ from './buttons/ButtonFAQ.vue';

export default Vue.extend({
	name: 'SectionInfo',
	components: {
		ButtonFAQ,
	},
	data: () => ({
		image: require('./../assets/images/2022/xrink_new3.png'),
		Dialog: false,
		WorkHours: WORKHOURS,
	}),
	methods: {
		ScrollTo(selector: string) {
			let el = document.querySelector(selector);
			if (el) el.scrollIntoView();
		},
	},
	computed: {
		workingSeason() {
			// Date calculation
			let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
			let saleStart = new Date(DATE_SALE_START);
			let saleEnd = new Date(DATE_SALE_END);
			let dateUntil = new Date(new Date(saleEnd).setDate(new Date(saleEnd).getDate() - 1));
			// String formation
			let dateStart = saleStart.getDate();
			let monthStart = months[saleStart.getMonth()];
			let dateEnd = dateUntil.getDate();
			let monthEnd = months[dateUntil.getMonth()];
			return `с ${dateStart} ${monthStart} по ${dateEnd} ${monthEnd}`;
		},
	},
});
</script>
